import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import Container from 'components/Container';
import Magnet from 'icons/magnet';
import PodiumTrophy from 'icons/podium-trophy';
import ArrowsSameDirection from 'icons/arrows-same-direction';
import Medal from 'icons/medal';

const mock = [
  {
    title: 'Schneller, intuitiver Kaufprozess in Echtzeit',
    subtitle:
      'Lass Deine Kunden in Windeseile sehen, was Dein Produkt kann. Hole Deine Kunden genau dort ab, wo sie stehen und gib Ihnen ein kinderleichtes Werkzeug an die Hand.',
    icon: <Magnet width={"60px"}/>,
  },
  {
    title: 'Fesselnde Produktdarstellung',
    subtitle:
      'Die Aufmerksamkeit von Kunden ist heutzutage sehr limitiert. Verschaffe Dir den vollen Fokus Deiner Kunden, indem Du Dein Produkt modern und wertig präsentierst.',
    icon: <Medal width={"60px"}/>,
  },
  {
    title: 'Kundenbindung erzeugen',
    subtitle:
      'Du bindest Kunden an Dich, wenn sie sich Dein Produkt auf Ihr Bedürfnisse anpassen können. Nutze diesen starken Effekt und gewinne mehr Kunden für Dich.',
    icon: <ArrowsSameDirection width={"60px"}/>,
  },
];

const AttractCustomers = () => {
  return (
    <Box 
    // bgcolor={'rgb(250,250,250)'}
    >
      <Container sx={{maxWidth: "850px"}}>
        <Box>
          <Box marginBottom={4}>
            <Typography
              variant={'h4'}
              gutterBottom
              align={'left'}
              sx={{ fontWeight: "bold", textAlign: "center", color: '#333', pb:3 }}
            >
              {`So gewinnst Du mehr Kunden in ${new Date().getFullYear()}`}
            </Typography>
            {/* <Typography
              variant={'h6'}
              component={'p'}
              color={'text.secondary'}
              align={'center'}
              sx={{ color: 'common.white' }}
            >
              3D bewirkt Wunder im Vertrieb, denn 
            </Typography> */}
          </Box>
          <Grid container spacing={2}>
            {mock.map((item, i) => (
              <Grid item xs={12} md={12} key={i}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                  }}
                >
                  {/* <span style={{color: "black"}}>{item.icon}</span> */}
                  
                  <Typography
                    variant={'body'}
                    gutterBottom
                    align={'left'}
                    sx={{ fontWeight: 500, color: '#333' }}
                  >
                    {item.title}
                  </Typography>
                  <Typography variant={"body"} sx={{ textAlign: "left" }} align={'center'}  color="text.secondary">
                    {item.subtitle}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default AttractCustomers;
