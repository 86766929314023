/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import LaptopSkeletonIllustration from "svg/illustrations/LaptopSkeleton";

import Container from "components/Container";

const Hero = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        // backgroundColor: theme.palette.alternate.main,
        // backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
        position: "relative",
      }}
    >
     
      <Container sx={{ position: "relative" }}>
        <Box position={"relative"} zIndex={3}>
          <Grid container spacing={4}>
            <Grid item container alignItems={"center"} xs={12} md={6}>
              <Box>
                <Box marginBottom={2}>
                  <Typography
                    component={"span"}
                    variant="h3"
                    sx={{ fontWeight: 700, fontFamily: '"Space Grotesk", sans-serif' }}
                  >
                    Für Hersteller konzipiert, die{" "}
                    <Typography
                      component={"span"}
                      variant={"inherit"}
                      color={"primary"}
                      sx={{
                        background: `linear-gradient(180deg, transparent 82%, ${alpha(
                          theme.palette.secondary.main,
                          0.3
                        )} 0%)`,
                      }}
                    >
                      mehr Umsatz in kürzerer Zeit
                    </Typography>{" "}
                    erreichen wollen.{" "}
                  </Typography>
                </Box>
                <Typography variant="h6" component="p" color={"text.secondary"}>
                  Werde zum Marktführer in Deiner Branche und begeistere Deine
                  Kunden und Mitarbeiter mit den verkaufsoptimierten 3D-Vertriebstools von
                  Configora.
                </Typography>
                <Box
                  display="flex"
                  flexWrap="wrap"
                  justifyContent={"flex-start"}
                  marginTop={4}
                >
                  {[
                    "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/garten_kubus_icon.png?alt=media&token=5509aa3c-790a-4ca3-b460-f38dfbdaedbf",
                    "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/klangschild.png?alt=media&token=d1ddf359-b9be-4ad4-a8b4-cf049b5d0c12",
                    "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/walkingboxes-logo-hoch.png?alt=media&token=49bbe87b-4932-4291-b6fa-86cbd8058a35",
                    "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/cornelius_holzarbeiten_logo.png?alt=media&token=02e7a052-b6e7-43b2-afc1-37db23ed6d1c",
                    "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/CUBEfx_Logo_new.png?alt=media&token=33a5bd8e-b5b9-4382-bd0e-e03d06328928",
                  ].map((item, i) => (
                    <Box
                      maxWidth={window.innerWidth > 800 ? 70 : 60}
                      marginTop={2}
                      marginRight={window.innerWidth > 800 ? 4 : 0}
                      key={i}
                      sx={{ margin: "auto" }}
                    >
                      <Box
                        component="img"
                        // height={1}
                        width={window.innerWidth > 800 ? 0.82 : 0.4}
                        src={item}
                        alt="..."
                        sx={{
                          margin: "auto",
                          filter:
                            theme.palette.mode === "dark"
                              ? "brightness(0.6) invert(0.7)"
                              : "contrast(0) brightness(0.3)",
                        }}
                      />
                    </Box>
                  ))}
                </Box>
                {/* <Box
                  display="flex"
                  flexWrap="wrap"
                  justifyContent={"flex-start"}
                  marginTop={4}
                  sx={{color: "#677788", background: "#aaa"}}
                >
                  <small style={{fontSize: "18px", fontWeight: "500"}}>In den Branchen<br/>
                  <span>Garten, Wohnen, Office, Mobilität, E-Commerce, Industrie u.v.m.</span></small>
                  

                </Box> */}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <Box
                sx={{
                  position: "relative",
                  marginX: "auto",
                  perspective: 1500,
                  transformStyle: "preserve-3d",
                  perspectiveOrigin: 0,
                }}
              >
                <Box
                  sx={{
                    position: "relative",
                    marginX: "auto",
                    transform: "rotateY(-35deg) rotateX(15deg) translateZ(0)",
                    maxWidth: "96%",
                  }}
                > */}
                  <Box>
                    {/* <Box
                      position={'relative'}
                      zIndex={2}
                      maxWidth={1}
                      height={'auto'}
                      sx={{ verticalAlign: 'middle' }}
                    >
                      <LaptopSkeletonIllustration />
                    </Box> */}
                    {/* <Box
                      position={'absolute'}
                      top={'8.4%'}
                      left={'12%'}
                      width={'76%'}
                      height={'83%'}
                      border={`1px solid ${theme.palette.alternate.dark}`}
                      zIndex={3}
                    > */}
                    <Box
                      component={"img"}
                      //src="https://assets.maccarianagency.com/screenshots/dashboard.png"
                      src={window.innerWidth > 800 ?"https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/std_brks_mock.png?alt=media&token=d0f817ec-c063-42b9-aab4-7347b289de00":"https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/Macbook-Air-wohnen-office.garten-kubus.de.png?alt=media&token=333c73e1-edba-4b63-9af5-1e32c9f1be81"}
                      alt="Image Description"
                      width={1}
                      height={1}
                      sx={{
                        marginTop: window.innerWidth > 800 ? "inherit" : "10px",
                        filter: "drop-shadow(1rem 0.35rem 1rem rgba(0, 0, 0, 0.5))",
                        objectFit: "cover",
                        // filter:
                        //   theme.palette.mode === 'dark'
                        //     ? 'brightness(0.7)'
                        //     : 'none',
                      }}
                    />
                  </Box>
                  {/* </Box> */}
                {/* </Box>
              </Box> */}
            </Grid>
          </Grid>
        </Box>
      </Container>
      {/* <Box
        component={"svg"}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1921 273"
        sx={{
          position: "absolute",
          width: "100%",
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: 1,
          height: "35%",
        }}
      >
        <polygon
          fill={theme.palette.background.paper}
          points="0,273 1921,273 1921,0 "
        />
      </Box> */}
    </Box>
  );
};

export default Hero;
