import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import Container from 'components/Container';
import Magnet from 'icons/magnet';
import PodiumTrophy from 'icons/podium-trophy';
import ArrowsSameDirection from 'icons/arrows-same-direction';
import Medal from 'icons/medal';

const mock = [
  {
    title: 'Umsatz anziehen',
    subtitle:
      'Ein starkes digitales Angebot auf Deiner Website zieht Kunden aus dem Web an und steigert die Verweildauer auf Deiner Website. Damit erhöht sich ganz natürlich auch die Anzahl an Kaufabschlüssen und Dein Umsatz.',
    icon: <Magnet width={"60px"}/>,
  },
  {
    title: 'Wettbewerbsvorteil aufbauen',
    subtitle:
      'Mit einem 3D-Konfigurator stichst Du aus der Masse heraus und setzt Deine Produkte optimal in Szene. Du wirst so als Markführer wahrgenommen und gewinnst ab jetzt die idealen Kunden für dich.',
    icon: <Medal width={"60px"}/>,
  },
  {
    title: 'Schnell und einfach verkaufen',
    subtitle:
      'Digitalisiere den Produktauswahlprozess bis hin zur Angebotserstellung mit unseren 3D-Konfiguratoren und smarter Prozessautomatisation. So bindest Du Kunden und Umsatz an Dein Unternehmen.',
    icon: <ArrowsSameDirection width={"60px"}/>,
  },
];

const WithDarkBackgroundAndSimpleDescriptionBox = () => {
  return (
    <Box 
    // bgcolor={'rgb(250,250,250)'}
    >
      <Container>
        <Box>
          <Box marginBottom={4}>
            <Typography
              variant={'h4'}
              gutterBottom
              align={'center'}
              sx={{ fontWeight: "bold", color: '#222', pb:6 }}
            >
              Das erreichst Du mit unseren vertriebsoptimierten 3D-Erlebnissen
            </Typography>
            {/* <Typography
              variant={'h6'}
              component={'p'}
              color={'text.secondary'}
              align={'center'}
              sx={{ color: 'common.white' }}
            >
              3D bewirkt Wunder im Vertrieb, denn 
            </Typography> */}
          </Box>
          <Grid container spacing={2}>
            {mock.map((item, i) => (
              <Grid item xs={12} md={4} key={i}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'left',
                  }}
                >
                  {/* <span style={{color: "black"}}>{item.icon}</span> */}
                  
                  <Typography
                    variant={'body'}
                    gutterBottom
                    align={'left'}
                    sx={{ fontWeight: 600, color: '#222' }}
                  >
                    {item.title}
                  </Typography>
                  <Typography variant={"body"} sx={{ color: 'text.secondary', textAlign: "left" }} align={'center'}>
                    {item.subtitle}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default WithDarkBackgroundAndSimpleDescriptionBox;
