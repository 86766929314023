/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useTheme } from "@mui/material/styles";
import { Avatar, Button, colors } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import Carousel from "react-material-ui-carousel";

const CaseStudy3 = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box
      padding={{ xs: 2, sm: 4 }}
      borderRadius={0}
      bgcolor={"#f5f5f5"}
      data-aos={"fade-up"}
    >
       <Typography fontWeight={700} variant={"h4"} mb={3}>#3 Case Study - Klangschild GmbH</Typography>
      <Grid
        container
        spacing={isMd ? 4 : 2}
        flexDirection={{ xs: "column-reverse", md: "row" }}
      >
        <Grid item xs={12} md={8}>
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid item xs={12}>
       
                <Carousel navButtonsAlwaysVisible autoPlay={false}>
                {[
                  <Box
                    component={"img"}
                    loading="lazy"
                    height={1}
                    width={1}
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/kl_mock.jpg?alt=media&token=8d6ead42-6e53-4718-bde8-bcafaf1a92a0"
                    }
                    alt="..."
                    borderRadius={2}
                    maxWidth={1}
                    height={400}
                    sx={{
                      objectFit: "cover",
                    }}
                  />,
                  <Box
                    component={"img"}
                    loading="lazy"
                    height={1}
                    width={1}
                    src={
                      " https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/kl_mockup.jpg?alt=media&token=d6cfec9a-9b62-48a6-b237-3b3f13f5605d"
                    }
                    alt="..."
                    borderRadius={2}
                    maxWidth={1}
                    height={550}
                    sx={{
                      objectFit: "cover",
                    }}
                  />,
                  <Box
                    component={"img"}
                    loading="lazy"
                    height={1}
                    width={1}
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/kl_1.png?alt=media&token=44996979-a5fe-4168-9bcb-ca187821414c"
                    }
                    alt="..."
                    borderRadius={2}
                    maxWidth={1}
                    height={550}
                    sx={{
                      objectFit: "cover",
                    }}
                  />,
                  // <iframe width="100%" height="400" src="https://www.youtube.com/embed/kYg9B7PDUok?si=7pv4WctzKbGxgv0u" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>,
                  
                <Box
                component={"img"}
                loading="lazy"
                height={1}
                width={1}
                src={
                  "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/klangschild_teaser.png?alt=media&token=ecc65189-c8e0-40de-b8ff-f046f49d769b"
                }
                alt="..."
                borderRadius={2}
                maxWidth={1}
                height={450}
                sx={{
                  objectFit: "cover",
                }}
              />,
                
                  <Box
                    component={"img"}
                    loading="lazy"
                    height={1}
                    width={1}
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/kl_2.png?alt=media&token=c95ca782-5d4f-40e8-97bc-027f4b12f48a"
                    }
                    alt="..."
                    borderRadius={2}
                    maxWidth={1}
                    height={450}
                    sx={{
                      objectFit: "cover",
                    }}
                  />,
                ].map((item, i) => item)}
              </Carousel>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={"h6"} fontWeight={700} gutterBottom>
                Ziel
              </Typography>
              <Typography component={"p"}>
                Kunden sollen schnell und einfach zu Ihrem Wunschprodukt finden.
                Dabei soll die Aufmerksamkeitsspanne optimal ausgenutzt wreden
                und in qualitative Kundenanfragen verwandelt werden. Die
                Bearbeitung von Anfragen soll mit wenigen Klicks möglich sein
                mit einer "Digitalen Vertriebsmaschine".
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={"h6"} fontWeight={700} gutterBottom>
                Lösung
              </Typography>
              <Typography component={"p"}>
                Bereits nach 2 Monaten war der 3D-Konfigurator im Einsatz und die ersten Umsatzsteigerungen wurden erzielt. Kundenanfragen werden von den Mitarbeitern sogar vom Handy aus per Konfigurator bearbeitet, und Kunden erhalten in Kürester Zeit eine Preisauskunft (inkl. Lieferkostenkalkulation) und eine 3D-Ansicht der für Sie passenden Klangschild-Konfiguration.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <center>
                <a href={"https://buy.klangschild.de"} target={"_blank"}>
                  <Button
                    sx={{
                      "&:hover": { background: "#222222" },
                      background: "#222222",
                      color: "white",
                      width: "230px",
                      borderRadius: "22px",
                    }}
                  >
                    Zum Konfigurator
                  </Button>
                </a>
              </center>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={{ xs: "flex-start", md: "space-between" }}
            height={1}
          >
            <Box>
              <Typography variant={"h6"} fontWeight={700} gutterBottom>
                Problem
              </Typography>
              <Typography

              // color={
              //   theme.palette.mode === 'light'
              //     ? 'text.secondary'
              //     : 'text.primary'
              // }
              >
                Kunden brauchten sehr lange, um das modulare Prinzip von
                Klangschild zu verstehen und haben deshalb nicht angefragt.
                Zudem war der Beratungsprozess sehr umständlich und frustrierend
                für Kunden und Mitarbeiter.
              </Typography>
            </Box>
            <Box
              component={Card}
              marginTop={{ xs: 2, md: 2 }}
              boxShadow={0}
              borderRadius={2}
            >
              <CardContent sx={{ padding: { xs: 2, sm: 4 } }}>
                <center>
                  <Avatar
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/yellow-web-6310c.appspot.com/o/cornelius_nicolai.png?alt=media&token=fae7dc0d-e295-4260-b471-0b85e30c4893"
                    }
                    sx={{ marginRight: 1, height: "140px", width: "140px" }}
                  />
                </center>
                <br />
                <Typography component={"p"}>
                  Ich habe schnell im Gespräch gemerkt, dass Johannes und Martin eine wirklich smarte digitale Vision für Klangschild haben und das Unternehmen im Bereich Marketing und digitaler Vertrieb mit viel Engagement auf eine neue Ebene haben werden. Und das haben Sie dann auch getan. Ich empfehle jedem Hersteller, mit den beiden zu arbeiten.
                </Typography>
                <Box marginTop={{ xs: 2, sm: 4 }}>
                  <Typography variant={"subtitle1"} sx={{ fontWeight: 700 }}>
                    Cornelius Nicolai
                  </Typography>
                  <Typography color="text.secondary">
                    Geschäftsführer
                  </Typography>
                </Box>
                <br />
                <Box
                  component="img"
                  height={1}
                  width={1}
                  src={
                    "https://assets-global.website-files.com/662b8081b194cceebff5a6f4/663344c11cf4bcb9c94971d9_image2vector%20(7).svg"
                  }
                  alt="..."
                  maxWidth={150}
                  marginBottom={2}
                  sx={{
                    filter:
                      theme.palette.mode === "dark"
                        ? "brightness(0) invert(0.7)"
                        : "none",
                  }}
                />
              </CardContent>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CaseStudy3;
